<template>
    <div class="subscribe-project-box">
        <CompHeader title="参与的云养计划" />
        <template v-if="isLoad">
            <template v-if="list.length">
                <template v-for="(item, index) in list" :key="index">
                    <div class="pro-item">
                        <div class="title-box">
                            <!-- <img @click="goHomePage(item.user_id)" :src="$imgUrlFilter(item.icon, 120)" /> -->
                            <div class="img" @click="goHomePage(item.user_id)">
                                <CompAvtor :img_src="item.icon" size="120" />
                            </div>
                            <div class="name-box">
                                <div class="name">{{item.nickname}}</div>
                                <div class="des">云养计划标题：{{item.pro_title}}</div>
                            </div>
                        </div>
                        <div class="pro-info">
                            <div>订阅金额￥{{item.backer_money}}/月</div>
                            <i class="icon"></i>
                            <div>本月订阅者{{item.backer_count}}人</div>
                            <el-popover
                                placement="top"
                                popper-style="width: 120px; min-width: 120px; padding: 10px;border-radius: 8px;">
                                <template #reference>
                                    <div class="btn">查看记录</div>
                                </template>
                                <div class="qr-box">
                                    <div class="qr">
                                        <CompQrcode :id="'QrCode'" :text="`${domain.subscribe}/project/${item.pro_id}.html`" />
                                    </div>
                                    <div>扫码查看详情</div>
                                </div>
                            </el-popover>
                        </div>
                    </div>  
                </template>
                <CompPagination :total="total" :pageSize="page_rows" @change="pageChange"/>
            </template>
            <CompError
                v-else
                class="cont-nothing-box"
                message="暂无参与的云养计划"
                icon-name="empty_search_icon"
                />
        </template>
    </div>
</template>

<script>
import domain from '@/service/domain'

import { mapState } from "vuex"
import CompHeader from "@@/common/header.vue"
import CompPagination from "@@/common/pagination.vue"
import CompQrcode from '@@/compose/qrcode.vue'
import CompError from "@@/common/error.vue"
import CompAvtor from "@@/compose/avtor.vue"

import { GetSubscribePro } from "@api/index"
export default {
    components: {
        CompHeader,
        CompPagination,
        CompQrcode,
        CompError,
        CompAvtor
    },
    computed: {
        ...mapState({
            userId: state => state.user.userId
        }),
    },
    data() {
        return {
            domain,
            isLoad: false,
            page_index: 1,
            page_rows: 6,
            total: 0,
            list: [],
            subscribe_count: 0
        }
    },
    async mounted() {
        await this.getList()
        this.isLoad = true
    },
    methods: {
        async getList() {
            const { status, data } = await GetSubscribePro({
                user_id: this.userId,
                to_user_id: this.userId,
                page_index: this.page_index,
                page_rows: this.page_rows
            })
            if (status == 0) {
                this.list = data.pro_list
                this.total = data.subscribe_count
            }
        },
        pageChange(val) {
            this.page_index = val
            this.getList()
        },
        goHomePage(user_id) {
            this.$router.push(`/user/homepage/${user_id}`)
        }
    }
}
</script>

<style lang="scss" scoped>
.subscribe-project-box {
    .pro-item {
        padding: 20px 0;
        .title-box {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            .img {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                margin-right: 11px;
                cursor: pointer;
                overflow: hidden;
            }
            .name-box {
                padding-top: 2px;
                line-height: 24px;
                .name {
                    font-weight: bold;
                    font-size: 18px;
                    margin-bottom: 1px;
                }
                .des {
                    color: #7F7F7F;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
        }
        .pro-info {
            display: flex;
            align-items: center;
            padding: 10px 10px 10px 20px;
            background-color: #F8F8F8;
            border-radius: 8px 8px 8px 8px;
            color: #7F7F7F;
            font-size: 16px;
            i {
                display: block;
                width: 1px;
                height: 14px;
                background-color: #E8E8E8;
                margin: 0 18px;
            }
            .btn {
                margin-left: auto;
                width: 100px;
                text-align: center;
                line-height: 40px;
                border-radius: 4px 4px 4px 4px;
                border: 1px solid #B1B1B1;
                cursor: pointer;
                &:hover {
                    border-color: #1A1A1A;
                    color: #1A1A1A;
                }
            }
        }
    }
}
.qr-box {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 1;
    .qr {
        width: 100px;
        height: 100px;
        margin-bottom: 8px;
    }
}
</style>
