<template>
    <el-image class="avtor" :src="$imgUrlFilter(img_src, size)">
        <template #error>
            <img src="https://s.moimg.net/m/img/placeholder/headPic.png" />
        </template>
    </el-image>
</template>

<script>
export default {
    props: {
        img_src: {
            type: String
        },
        size: {
            type: String
        }
    }
}
</script>

<style lang="scss" scoped>
.avtor {
    width: 100%;
    height: 100%;
}
</style>
